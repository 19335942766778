$(function() {
	if (navigator.userAgent.indexOf("Cordova") !== -1) {
		$.cookie("Cordova", true, { path: "/", expires: 36500 });
	}
	$(document)
		.on("click", "#btn-login", function(e) {
			var form = $(this).parents("form");
			e.preventDefault();
			var inputs = form.find("input:visible");
			var valid = true;
			inputs.each(function(i, input) {
				var $input = $(input);
				if ($input.val() == null || $input.val() === "") {
					valid = false;
					var $inputContainer = $input.parents(".fg-line");
					$inputContainer.addClass("has-error");
					var $inputGroup = $input.parents(".input-group");
					if ($inputGroup.length === 0) {
						$inputContainer.addClass("animated shake");
					} else {
						$inputGroup.addClass("animated shake");
					}
				}
			});
			if (valid) {
				if (window.location.hash)
					form.attr("action", form.attr("action") + encodeURIComponent(window.location.hash));
				var email = $("#email").val() || $("[name='email']").val();
				$("#needRedirect").val(localStorage.getItem("last-user-login") != null ? Boolean(localStorage.getItem("last-user-login") != window.Helper.String.hash(email)) : false );

				form.submit();
			}
			return false;
		}).on("change, keydown, keyup", ".has-error input", function(e) {
			$(this).parents(".has-error").removeClass("has-error");
		}).on("click", ".remove-login", function(e) {
			e.preventDefault();
			$.cookie("Login", null, { path: "/", expires: -1 });
			window.location.reload();
		}).on("click", "#goto-website", function(e) {
			e.preventDefault();
			$(".app-suggestion").hide();
			$("#l-login").removeClass("hide");
			$("#l-login").show();
			return false;
		}).on("click", "h4#logo-or-title", function (e) {
			$("#version-number").fadeToggle("medium");
		});
	if (!!window.plugins && !!window.plugins.waitingDialog) {
		window.plugins.waitingDialog.hide();
	}
});