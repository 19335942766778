/// <reference path="../system/jquery-1.11.3.min.js" />
/// <reference path="Helper.Url.js" />
(function (Helper, $) {
	var defaultLanguage = null;
	var fetchedStrings = {};

	Helper.String = {
		base64toBlob: function (base64Data, contentType) {
			contentType = contentType || "";
			var sliceSize = 1024;
			var byteCharacters = atob(encodeURI(base64Data));
			var bytesLength = byteCharacters.length;
			var slicesCount = Math.ceil(bytesLength / sliceSize);
			var byteArrays = new Array(slicesCount);

			for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
				var begin = sliceIndex * sliceSize;
				var end = Math.min(begin + sliceSize, bytesLength);

				var bytes = new Array(end - begin);
				for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
					bytes[i] = byteCharacters[offset].charCodeAt(0);
				}
				byteArrays[sliceIndex] = new Uint8Array(bytes);
			}
			return new Blob(byteArrays, { type: contentType });
		},

		getTranslatedString: function (alienstring, defaultstring, language) {
			if (!alienstring && !defaultstring) {
				return null;
			}
			language = language || defaultLanguage;
			var fetched = !!language && !! fetchedStrings[language] ? fetchedStrings[language][alienstring] : fetchedStrings[alienstring];
			if (!!fetched) {
				return fetched;
			}
			var containsSpecialChars = alienstring.match(/\W+/) != null;
			var current = !containsSpecialChars ? $("#" + alienstring + ".translation, #" + alienstring + "-translation").eq(0) : [];
			if (current.length > 0) {
				return current.text();
			}
			else if (!!defaultstring) {
				return defaultstring;
			} else {
				return 'Translation Missing: "' + alienstring + '"';
			}
		},

		fetchStringTranslation: function(alienstring, language) {
			var deferred = new $.Deferred();
			if (!!fetchedStrings[alienstring]) {
				deferred.resolve(fetchedStrings[alienstring]);
			} else {
				var url = Helper.Url.resolveUrl("~/Resource/" + alienstring + ".json");
				if (!!language) {
					url += "?language=" + language;
				}
				$.ajax(url,
				{
					cache: true,
					success: function(data) {
						fetchedStrings[alienstring] = data.Value;
						deferred.resolve(data.Value);
					}
				});
			}
			return deferred.promise();
		},

		setDefaultLanguage: function(language) {
			defaultLanguage = language;
		},

		setStringTranslation: function(resourceKey, translation, language) {
			if (!!language) {
				fetchedStrings[language] = fetchedStrings[language] || {};
				fetchedStrings[language][resourceKey] = translation;
			} else {
				fetchedStrings[resourceKey] = translation;
			}
		},

		limitStringWithEllipses: function (longString, maxLength) {
			var length = maxLength || 9;
			if (longString.length > length && length > 0) {
				var shorttext = longString.substr(0, length - 4);
				shorttext += "...";
				return shorttext;
			}
			else {
				return longString;
			}
		},

		htmlEntities: function (unsafetext) {
			unsafetext = unsafetext.toString();
			if (!unsafetext) return "";
			var temporaryDiv = $("<div></div>").addClass("hide").appendTo("body").text(unsafetext);
			var safetext = temporaryDiv.html();
			temporaryDiv.remove();
			return safetext;
		},

		hash: function (str) {
			var hash = 0, i, chr, len;
			if (!str || str.length === 0) return hash;
			for (i = 0, len = str.length; i < len; i++) {
				chr = str.charCodeAt(i);
				hash = ((hash << 5) - hash) + chr;
				hash |= 0;
			}
			return hash;
		},

		isGuid: function (str) {
			return /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi.test(str);
		},

		emptyGuid: function () {
			return "00000000-0000-0000-0000-000000000000";
		},

		trim: function(x) {
			var u = window.ko.unwrap(x) || "";
			return u.trim();
		},

		replaceLineBreaks: function(html) {
			return html.replace(/(?:\\r\\n|\\r|\\n)/g, "<br>");
		}
	};

})((window.Helper = window.Helper || {}), jQuery);